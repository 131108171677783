import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { RobotService } from '../..';
@Component({
  selector: 'app-robot-action-dialog',
  templateUrl: './robot-action-dialog.component.html',
  styleUrls: ['./robot-action-dialog.component.scss']
})
export class RobotActionDialogComponent implements OnInit {
  public qrCode;
  public count;
  public robot;
  public actionId;
  email = new FormControl();
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<RobotActionDialogComponent>,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private robotService: RobotService
  ) {
    this.robot = data?.robot;
    this.count = data?.count;
    this.qrCode = this.sanitizer.bypassSecurityTrustResourceUrl(`${data?.qrCode}`);
    this.actionId = data?.actionId;
  }

  ngOnInit(): void {
  }

  sendMail() {
    this.robotService.sendQrMail(this.actionId, this.email.value)
      .subscribe();
  }

}

export const accessRoles: { [key: string]: string[] } = {
    "dashboard": ["service-admin","store-admin","store-agent"],
    "service-cases": ["service-admin", "store-admin", "store-agent"],
    "users-list": ["service-admin", "service-manager"],
    "clients-list": ["service-admin", "service-manager"],
    "diagnostics": ["service-admin", "store-admin", "store-agent"],
    "robots-list": ["service-admin", "service-manager"],
    "robots-requests": ["service-admin", "service-manager"],
    "device-tool": ["service-admin", "service-manager"],
    "search-device": ["service-admin", "store-admin", "store-agent"],
    "device-diagnostic": ["service-admin", "store-admin", "store-agent"],
    "service-item-overview": ["service-admin", "store-admin", "store-agent"],
    "stores-list": ["service-admin", "store-admin", "store-agent"],
    "enrollment": ["service-admin", "service-manager"],

    "form/:id":["service-admin"]
  };

  export const validRoles: string[] = ['service-admin','service-manager','store-admin','store-agent']
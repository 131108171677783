import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'calculateDuration'
})
export class CalculateDurationPipe implements PipeTransform {

  transform(start: string, end: string): string {
    const startTime = moment(start);
    const endTime = moment(end);
    const duration = moment.duration(endTime.diff(startTime));

    const minutes = Math.floor(duration.asMinutes());
    const seconds = duration.seconds();

    return `${minutes}m ${seconds}s`;
  }
}

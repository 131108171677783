import { throwError, Observable } from "rxjs";
import { catchError, delay, filter } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import * as moment from "moment";
@Injectable()
export class DashboardService {
  private serviceUrl = `${environment.apiUrl}/service`;

  constructor(private router: Router, private http: HttpClient) {}

  getDevicesCountPerService(): Observable<any> {
    return this.http.get(`${this.serviceUrl}/service-case/stats/unresolved-count`)
    .pipe(catchError((error: any) => throwError(error)));
  }
  getAllUnresolvedCases(): Observable<any> {
    return this.http.get(`${this.serviceUrl}/service-case/stats/cases-per-type`)
    .pipe(catchError((error: any) => throwError(error)));
  }
  
  getDashboardData(filterObject): Observable<any> {
    let params = new HttpParams();
    Object.keys(filterObject).forEach(key => {
      if (Array.isArray(filterObject[key])) {
        params = params.append(key, filterObject[key]);
      }
    })
    // Handle date values
    Object.entries(filterObject.date).forEach(([dateKey, dateValue]) => {
      if (dateValue) {
        params = params.append(dateKey, moment(dateValue).format('YYYY-DD-MM'));
      }
    });
    return this.http.get(`${this.serviceUrl}/desk/dashboard`, {params})
    .pipe(catchError((error: any) => throwError(error)));
  }

  getServiceCaseCount(filterObject?): Observable<any> {
    let params = new HttpParams();
    
    Object.keys(filterObject).forEach(key => {
      if (Array.isArray(filterObject[key])) {
        params = params.append(key, filterObject[key]);
      }
    })
    
    return this.http.get(`${this.serviceUrl}/desk/count-cases`, { params })
    .pipe(catchError((error: any) => throwError(error)));
  } 
}

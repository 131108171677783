import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Injectable()
export class DialogService {
  public dialogConfig = new MatDialogConfig();

  constructor(private dialog: MatDialog,) { }

  openDialog(component: ComponentType<any>,data: MatDialogConfig): Observable<any> {
    return this.dialog
      .open(component, data)
      .afterClosed();
  }
}

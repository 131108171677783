import { Component, OnInit } from '@angular/core';
import { AuthenticationService, NotificationService, UserModel, UserService } from 'src/app/shared';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public currentUser: UserModel = new UserModel();
  totalServiceCases: number;
  constructor(
    private authService: AuthenticationService,
    private userService: UserService,
    private notificationService: NotificationService
    ) { }

  ngOnInit() {
    this.getCurrentUser();
    this.checkAnfangNotifications();
  }

  public logout(): void {
    this.authService.logout();
    localStorage.removeItem("redirectUrl");
  }

  public getCurrentUser(): void {
    this.userService.getCurrentUser().subscribe((user: UserModel) => {
      this.currentUser = user;
    });
  }

  checkAnfangNotifications() {
    this.notificationService.getAnfangNotification()
      .subscribe(res => this.totalServiceCases = res?.count);
  }
}

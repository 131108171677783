import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "../services";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let previousUrl: string = state.url;

    if (localStorage.getItem("current_user")) {
      return true;
    }
    localStorage.setItem("redirectUrl", '' + previousUrl);
    this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
    return false;
  }
}

<mat-dialog-content class="flex-column text-center">
    <div>
        <h2 class="remove-pu__header text-22">{{serviceInfo?.title}}</h2>
    </div>
    <div class="divider"></div>
    <div class="flex-column">
        <p class="left remove-pu__text text-16">{{serviceInfo?.description}}</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="center justify-content-end">
    <button  mat-dialog-close class="btn btn-outline-primary mr-2">CLOSE</button>
    <!-- <button (click)="confirm()" class="btn btn-primary">GO TO ICLOUD</button> -->
</mat-dialog-actions>
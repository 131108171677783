import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-action-needed-dialog',
  templateUrl: './action-needed-dialog.component.html',
  styleUrls: ['./action-needed-dialog.component.scss']
})
export class ActionNeededDialogComponent implements OnInit {
  public resolveActionForm: FormGroup;
  public device: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ActionNeededDialogComponent>,
  ) {this.device = data;}

  ngOnInit(): void {
    this.resolveActionForm = this.fb.group({
      archive: null,
      service_case: null
    })
  }

  confirm() {
    this.dialogRef.close(this.resolveActionForm.value);
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-device-active-service',
  templateUrl: './device-active-service.component.html',
  styleUrls: ['./device-active-service.component.scss']
})
export class DeviceActiveServiceComponent implements OnInit {
  serviceInfo: {title: string, description: string};

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<DeviceActiveServiceComponent>,
  ) { 
    this.serviceInfo = data;
  }

  ngOnInit(): void {
  }
  
  confirm() {
    
  }
}

import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";


@Component({
  selector: "app-delete-modal",
  templateUrl: "./delete-modal.component.html",
  styleUrls: ["./delete-modal.component.scss"]
})
export class DeleteModalComponent implements OnInit {
  public customText: string = null;
  public header: string = null;
  public title: string = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<DeleteModalComponent>,
  ) {
    this.customText = data != null ? data.text : null;
    this.header = data != null ? data.header : 'Delete';
    this.title = data != null ? data.title : 'Are you sure?';
  }

  ngOnInit() {}

  confirm() {
    this.dialogRef.close(true);
  }
}

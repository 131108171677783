import { Injectable } from '@angular/core';
import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
@Injectable()
export class UploadService {
    private serviceUrl = `${environment.apiUrl}/service`;
    constructor(
        private router: Router,
        private http: HttpClient
    ) { }
    uploadEmployees(company_id: number, file): Observable<any>{
        return this.http.post(`${this.serviceUrl}/company/bulk-employee?company_id=${company_id}&branch_id=${file.branch_id}`, file)
        .pipe(catchError((error: any) => throwError(error)));
    }
    uploadDevices(company_id: number, file): Observable<any>{
        return this.http.post(`${this.serviceUrl}/company/bulk-device?company_id=${company_id}&branch_id=${file.branch_id}`, file)
        .pipe(catchError((error: any) => throwError(error)));
    }
    uploadLifecycle(company_id: number, file): Observable<any>{
        return this.http.post(`${this.serviceUrl}/company/bulk-device-lifecycle?company_id=${company_id}&branch_id=${file.branch_id}`, file)
        .pipe(catchError((error: any) => throwError(error)));
    }
    uploadRentNumbers(company_id: number, file): Observable<any>{
        return this.http.post(`${this.serviceUrl}/company/map-rent-ids?company_id=${company_id}&branch_id=${file.branch_id}`, file)
        .pipe(catchError((error: any) => throwError(error)));
    }
}
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { BranchModel, BranchService } from '../..';
import { debounceTime, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-debitor-modal',
  templateUrl: './add-debitor-modal.component.html',
  styleUrls: ['./add-debitor-modal.component.scss']
})
export class AddDebitorModalComponent implements OnInit {
  debitorForm: FormGroup;
  company_id: number;
  branchList: BranchModel[] = [];
  filteredBranchesList: BranchModel[] = [];
  branchControlSubscription: Subscription;
  selectedListItem;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private branchService: BranchService,
    private fb: FormBuilder
  ) { 
    this.company_id = data?.company_id;
  }

  ngOnInit(): void {
    this.getCompanyBranches();
    this.debitorForm = this.fb.group({
      branch_id: [],
      haai_id: []
    })
    this.initFilters();
  }

  getCompanyBranches() {
    this.branchService.getBranches(this.company_id, '1')
      .subscribe(branches => this.branchList = branches.rows);
  }
  onAddDebitor(branch_id: number) {
    this.selectedListItem = branch_id;
  }
  onEditCancel() {
    this.selectedListItem = null;
    this.debitorForm.get('haai_id').reset();
  }
  initFilters() {
      this.branchControlSubscription = this.debitorForm.controls.branch_id.valueChanges
      .pipe(startWith(null))
      .pipe(debounceTime(500))
      .pipe(
        tap(val => {
          if (val && typeof val !== 'object') {
            this.branchService.getBranches(
              this.company_id,
              '1',
              this.debitorForm.value)
              .subscribe(res => {
                if (res.rows.length !== 0) {
                  this.branchList = res.rows;
                } else {
                  this.branchList = [];
                }
            });
          } else if (val === '') {
            this.getCompanyBranches();
          }
        })
      )
      .subscribe();
   
  }
  confirm(branch_id: number){
   const data: BranchModel = {
   haai_id: this.debitorForm.value.haai_id,
   id: branch_id
   };

    if (branch_id) {
      this.branchService.updateBranch(this.company_id, data,)
      .subscribe(res => this.getCompanyBranches());
    }
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import { EmployeeModel } from "../models";

@Pipe({ name: "fullname" })
export class FullNamePipe implements PipeTransform {
  transform(employee: EmployeeModel): string {
    if (employee) {
      const fullname = `${employee.first_name} ${employee.last_name}`;
      return fullname;
    }
  }
}

import { UserModel } from ".";
import { RobotModel } from "./robot.model";

export class RobotActionsModel {
  id: number;
  status: string;
  storetec_locker: RobotModel;
  storetec_locker_id: number;
  storetec_swap_request: any;
  storetec_device_ios: any;
  type: string;
  updated_at: string;
  created_at: string;
  user_id: number;
  user: UserModel
}

<mat-dialog-content>
    <div>
        <h2 class="remove-pu__header text-18">Service Case Information</h2>
    </div>
    <div class="divider"></div>

    <div>
        <div [formGroup]="scDataForm">
            <!-- Condition Description -->
            <ng-container *ngIf="scData?.condition_description">
                <div formGroupName="condition_description">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Description</mat-label>
                        <textarea matInput formControlName="condition"></textarea>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Cause of damage:</mat-label>
                        <textarea matInput formControlName="description"></textarea>
                    </mat-form-field>
                </div>
            </ng-container>

            <!-- Accessories -->
            <ng-container *ngIf="scData?.accessories">
                <div formGroupName="accessories">
                    <div formArrayName="list" class="d-flex justify-content-between">
                        <ng-container *ngFor="let accessoryControl of accessories.controls; let i = index">
                            <mat-checkbox
                                [formControlName]="i"
                                (change)="accessorySelected($event, i)">
                                <span class="overview-content">{{accessoriesList[i].name | uppercase}}</span>
                            </mat-checkbox>
                        </ng-container>
                    </div>

                    <!-- Accessory Description -->
                    <div class="d-flex" *ngIf="allowDescription">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Description</mat-label>
                            <textarea matInput
                                formControlName="accessory_description"
                                rows="10">
                            </textarea>
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="center justify-content-end">
    <button mat-dialog-close class="btn btn-outline-primary mr-2">Cancel</button>
    <button (click)="confirm()" class="btn btn-primary">Save</button>
</mat-dialog-actions>
import {
  DeviceVariantModel,
  BranchModel,
  MobileProviderModel,
  InsuranceModel,
  CompanyModel,
  EmployeeModel,
  DeviceStatusModel,
  DeviceTypeModel,
  DeviceLifecycleModel,
  DeviceActivityModel
} from "./";
import { DeviceModelModel } from './device-model.model';
import { DevicePriceModel } from './device-price.model';
import { DeviceSupplierModel } from './device-supplier.model';


export class DeviceModel {
  id: number;
  name: DeviceModelModel;
  serial_number: string;
  imei: string;
  device_status_id: number;
  created_at: string;
  updated_at: string;
  device_variant_id: number;
  device_type_id: string;
  device_variant: DeviceVariantModel;
  device_type: DeviceTypeModel;
  branch_id: number;
  branch: BranchModel;
  company_id: number;
  company: CompanyModel;
  mobile_provider_id: number;
  mobile_provider: MobileProviderModel;
  supplier_name: string;
  insurance_id: number;
  insurance: InsuranceModel;
  employee_id: number;
  employee: EmployeeModel;
  device_status: DeviceStatusModel;
  device_lifecycle: DeviceLifecycleModel;
  device_price: DevicePriceModel;
  device_lifecycle_logs: any;
  device_supplier: DeviceSupplierModel;
  device_activities: DeviceActivityModel[];
  assign: boolean;
  denormalized_device_variant: any
  reassign: boolean;
  doa: boolean;
  extend_date: string;
}
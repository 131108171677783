<form [formGroup]="debitorForm">
    <div mat-dialog-content class="action-needed-dialog">
      <div class="row debitor-modal-content">
        <div class="col-12 d-flex action-header-wrapper justify-content-between align-items-center">
          <h2 class="center action-header">Add or update debitor number</h2>
          <span class="material-icons action-close" mat-dialog-close>
            close
            </span>
        </div>
        <hr>
      </div>
      <div class="form-group row debitor-form">
          <div class="col-12">
            <mat-form-field class="full-width" appearance="outline">
                <input matInput formControlName="branch_id" autocomplete="off" placeholder="Search branch">
                <mat-autocomplete 
                    #branchFilter="matAutocomplete" 
                >
                <mat-option *ngFor="let branch of filteredBranchesList" [value]="branch" class="device-serial-option">
                    {{ branch.name }} <small>{{branch?.haai_id}}</small>
                </mat-option>
            </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-12">
              <div class="d-flex justify-content-between debitor-list">
                  <span>Branch</span>
                  <span>Debitor number</span>
              </div>
              <div class="mt-2 debitor-list-items" *ngFor="let branch of branchList; let branchIndex = index;">
                <div class="d-flex justify-content-between">
                    <span>{{branch?.name}}
                        <span 
                        class="add-debitor-btn" 
                        (click)="onAddDebitor(branchIndex)"
                        >{{!branch?.haai_id ? 'Add' : 'Edit'}}</span>
                    </span>
                    <span>{{branch?.haai_id || '/'}}</span>
                </div>
                <div class="form-fields-controls" *ngIf="branchIndex == selectedListItem">
                        <mat-form-field class="full-width" appearance="outline">
                            <input matInput placeholder="{{branch?.haai_id ? 'Edit' : 'Add'}} debitor number" formControlName="haai_id">
                        </mat-form-field>
                        <div mat-dialog-actions class="mb-2">
                            <button mat-button class="ml-auto  action-cancel" (click)="onEditCancel()">{{'Cancel' | uppercase}}</button>
                            <button mat-button class="blue-button" (click)="confirm(branch?.id)">{{'Save' | uppercase}}</button>
                        </div>
                    </div>
                </div>
          </div>
        </div>
    </div>
    
  </form>
  
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgxFileDropEntry, FileSystemFileEntry } from "ngx-file-drop";
import { ToastrService } from "ngx-toastr";
import { BranchModel } from "../..";
import { BranchService, UploadService } from "../../services";
import { saveAs } from "file-saver";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-upload-modal",
  templateUrl: "./upload-modal.component.html",
  styleUrls: ["./upload-modal.component.scss"],
})
export class UploadModalComponent implements OnInit {
  public uploadForm: FormGroup;
  public company_id: number;
  public company_name: string;
  public publicURL: string;
  public filesUrl = {
    manual_de: "manual_DE.pdf",
    manual_en: "manual_ENG.pdf",
  };
  branchesList: BranchModel[] = [];
  uploadTypeInfos = [
    {
      type: "device",
      icon: "devices",
      header: "Devices document",
      radioText: "Devices",
      file_example_url: "Device_upload_template.xlsx",
    },
    {
      type: "employee",
      icon: "group",
      header: "Employees document",
      radioText: "Employees",
      file_example_url: "Employee_upload_template.xlsx",
    },
    {
      type: "lifecycle",
      icon: "timelapse",
      header: "Lifecycles document",
      radioText: "Change lifecycle duration",
      file_example_url: "Lifecycle_upload_template.xlsx",
    },
    {
      type: "rent",
      icon: "eco",
      header: "CO2 document",
      radioText: "CO2 rent ID mapping",
      file_example_url: "Rent_Numbers_upload_template.csv",
    },
  ];
  constructor(
    private fb: FormBuilder,
    private uploadService: UploadService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<UploadModalComponent>,
    private toastr: ToastrService,
    private branchService: BranchService
  ) {
    this.company_id = data.company_id;
    this.company_name = data.company_name;
    this.publicURL = environment.apiUrl + "/public/";
  }

  ngOnInit() {
    this.uploadForm = this.fb.group({
      device: [],
      employees: [],
      branch_id: [],
      lifecycle: [],
      rentNumbersMap: [],
      upload_type_info: new FormControl(this.uploadTypeInfos[0]),
    });
    this.getBranchesOfCompany();
  }

  public getSelectedUploadTypeOption() {
    return this.uploadForm.get("upload_type_info")?.value?.type;
  }

  public droppedFile(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.handleUpladedFile(file);
        });
      } else {
        this.toastr.error("You must provide a valid file!");
      }
    }
  }
  getBranchesOfCompany() {
    this.branchService
      .getBranchesList(this.company_id, "1")
      .subscribe((branches) => (this.branchesList = branches));
  }
  public handleUpladedFile(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const uploadType = this.uploadForm.get("upload_type_info")?.value?.type;
    reader.onload = () => {
      if (uploadType === "device") {
        this.uploadForm.patchValue({ device: reader.result });
        console.log(this.uploadForm.value);
        if (this.uploadForm.value.device) {
          this.uploadService
            .uploadDevices(this.company_id, this.uploadForm.value)
            .subscribe(() => {
              this.toastr.success("Devices uploaded successfully");
              this.dialogRef.close();
            });
        }
      } else if (uploadType === "lifecycle") {
        this.uploadForm.patchValue({ lifecycle: reader.result });
        if (this.uploadForm.value.lifecycle) {
          this.uploadService
            .uploadLifecycle(this.company_id, this.uploadForm.value)
            .subscribe(() => {
              this.toastr.success("Devices uploaded successfully");
              this.dialogRef.close();
            });
        }
      } else if (uploadType === "rent") {
        this.uploadForm.patchValue({ rentNumbersMap: reader.result });
        if (this.uploadForm.value.rentNumbersMap) {
          this.uploadService
            .uploadRentNumbers(this.company_id, this.uploadForm.value)
            .subscribe((response) => {
              this.toastr.success("Rent numbers uploaded successfully");
              this.dialogRef.close();
            }, error => { 
              this.toastr.error("Something went wrong while uploading rent numbers.")
            });
        }
      } else {
        this.uploadForm.patchValue({ employees: reader.result });
        if (this.uploadForm.value.employees) {
          this.uploadService
            .uploadEmployees(this.company_id, this.uploadForm.value)
            .subscribe(() => {
              this.toastr.success("Employees uploaded successfully");
              this.dialogRef.close();
            });
        }
      }
    };
  }

  public downloadFile(fileName: string) {
    saveAs(`${this.publicURL}service-upload/example/${fileName}`, fileName);
  }
}

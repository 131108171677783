<form [formGroup]="resolveActionForm">
  <div mat-dialog-content class="action-needed-dialog">
    <div class="row action-header-content">
      <div class="col-12 d-flex action-header-wrapper justify-content-between align-items-center">
        <h2 class="center action-header">{{'Action needed / Swapped device'}}</h2>
        <span class="material-icons action-close" mat-dialog-close>
          close
          </span>
      </div>
      <hr>
    </div>
    <div class="form-group row">
        <div class="col-12">
          <h2 class="action-device-title my-4">{{'This device'}} {{device}} {{'has been swapped as an defective device'}}</h2>
          <h3 class="action-resolve-note mb-5">{{'Please process with this device in one of the following ways is order to resolve his status.'}}</h3>
        </div>
        <div class="col-md-12 col-lg-12">
          <div>
            <mat-checkbox class="example-margin" formControlName="archive">{{'Archive device'}}</mat-checkbox>
            <p>{{'The device will be removed from the device list'}}</p>
          </div>
          <div>
              <mat-checkbox class="example-margin" formControlName="service_case">{{'Create service case'}}</mat-checkbox>
              <p>{{'After filling up the form, a repair ticket will be created for the supplier'}}</p>
          </div>
        </div>
    </div>
  </div>
  <div mat-dialog-actions class="mb-2 mt-5">
    <button mat-button class="ml-auto blue-button" (click)="confirm()">{{'Resolve'}}</button>
    <button mat-button mat-dialog-close class="action-cancel">{{'Cancel'}}</button>
  </div>
</form>

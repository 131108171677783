<mat-dialog-content class="flex-column">
    <h2 class="text-24 text-black my-4">Terms & conditions</h2>
    <p class="text-14 text-black">
        Service AGB-s ab 19.2.2024

        Sämtliche Reparaturen und Garantieabwicklungen der HAAI GmbH erfolgen ausschließlich aufgrund der nachfolgenden
        Bedingungen:
        <br>
        1. Wir weisen darauf hin, dass Softwarefehler nur kostenpflichtig behoben werden, da hierfür kein Garantie- /
        Gewährleistungsanspruch besteht. Kosten ab € 34,90.
        <br>
        2. Es wird empfohlen, vor der Reparatur eine vollständige Datensicherung selbst vorzunehmen. Für Datenverluste
        wird keinerlei Haftung übernommen (ausgenommen Vorsatz oder grobe Fahrlässigkeit).
        <br>
        3. Sollte kein Garantieanspruch für das angeführte Produkt bestehen oder der Fehler nicht nachvollziehbar sein,
        wird für die Diagnose eine Überprüfungspauschale bzw. Kostenvoranschlag laut Preisliste* verrechnet. Ist vom
        Kunden/Auftraggeber ein Expressservice gewünscht (das Gerät wird im Reparaturprozess vorgezogen), ist ein Betrag
        von € 79,90 inkl. USt. zusätzlich zu allfälligen Kosten zu entrichten. Für Defekte, die auf Anwenderfehler,
        Verwendung inkompatibler Hardware oder fehlerhafte System-/Programmsoftware zurückzuführen sind, sowie
        Beschädigungen aufgrund unsachgemäßer Handhabung, besteht kein Serviceanspruch. Hier kann die Bearbeitung
        abgelehnt und kostenpflichtig laut Preisliste* verrechnet werden. Es gelten die Garantiebestimmungen des
        jeweiligen Herstellers.
        <br>
        4. Alle Apple Ersatzteilpreise sind Tauschpreise. Die defekten Teile werden an Apple zurückgesendet. Sollten Sie
        die defekten Ersatzteile benötigen (z.B. für Versicherungsfälle),
        teilen Sie uns das bitte vorab mit, da hierfür die Reparaturkosten höher ausfallen können.
        <br>
        5. iPhone/iPad/iPod können nur unter folgenden Bedingungen bearbeitet werden: "mein iPhone suchen" muss während
        der gesamten Servicedauer deaktiviert sein und alle original-Komponenten müssen vorhanden und verbaut sein. Wird
        im Zuge der Reparatur festgestellt, dass das Gerät nicht reparabel ist, oder ein Fremdeingriff stattgefunden hat
        oder nicht original Apple-Teile verwendet wurden, kann das Gerät unrepariert retourniert und die Überprüfung
        laut Preisliste* verrechnet werden.
        <br>
        6. *Preisliste inkl. USt.: Zubehör € 9,90 | iPod/AirPod/Beats € 29,90 | Handy/Smartphone/Tablet/Watch € 39,90 |
        MacBook € 89,90 | iMac € 99,90 | MacPro/Display € 129,90
        <br>
        7. Wir weisen ausdrücklich darauf hin, dass im Falle eines Reparaturauftrags für Folgeschäden und/oder
        Datenverlust keine Haftung übernommen werden kann, sofern es sich nicht um Vorsatz oder grobe Fahrlässigkeit
        handelt.
        <br>
        8. An Zubehör (z.B. Taschen, Displayfolien, Aufklebern, Sim Karten, etc.) und verbauten Teilen (z.B. Display,
        Batterie/Akku, Gehäuseteile, etc.), die nicht vom Hersteller erzeugt/freigegeben wurden, kann es aufgrund der
        schlechteren Qualität solcher nachgemachten Zubehör- und Ersatzteile im Zuge einer Reparatur zu Schäden bis hin
        zur Unbrauchbarkeit kommen. Für solche Schäden, die bei Reparatur eines Originalgerätes an nicht vom Hersteller
        hergestelltem/freigegebenem Zubehör und/oder verbauten Bauteilen entstehen, schließt HAAI die Haftung aus
        (ausgenommen Vorsatz oder grobe Fahrlässigkeit).
        Im Allgemeinen gelten die AGBs der HAAI GmbH. http://www.haai.at/agb
        <br>
        9. Die angeführten Daten werden an Apple gesendet und gemäß den Datenschutzrichtlinien
        (http://www.apple.com/at/legal/privacy) nicht für Marketingzwecke verwendet.
        <br>
        10. Wir weisen ausdrücklich darauf hin, dass das übernommene Gerät nur Zug um Zug gegen vollständige Bezahlung
        der Reparaturrechnung und Übergabe dieses Reparaturscheins ausgefolgt wird. Die Kontaktaufnahme durch uns
        erfolgt, sobald ein Kostenvoranschlag erstellt wurde, oder das angeführte Produkt abholbereit ist. Gerne lagern
        wir das Produkt nach Fertigstellung und Benachrichtigung durch uns, 30 Tage in unserer Filiale. Wird das Gerät
        nicht innerhalb dieser Frist abgeholt, geht die Gefahr des zufälligen oder leicht fahrlässigen Untergangs oder
        der zufälligen leicht fahrlässigen Untergangs oder
        der zufälligen leicht fahrlässigen Beschädigung auf den Kunden über. Außerdem ist die HAAI GmbH in diesem Fall
        berechtigt, eine angemessene Lagergebühr zu verrechnen.
        <br>
        11. Produkte, welche innerhalb von 10 Wochen ab Benachrichtigung durch uns nicht abgeholt werden, gehen in das
        Eigentum der HAAI GmbH über und können zur Abdeckung der entstandenen Kosten verwertet oder entsorgt werden,
        soweit dies nach gültigem Recht zulässig ist. Bereicherungsrechtliche Ansprüche des Kunden bleiben davon
        unberührt.
        <br>
        12. Bitte überprüfen Sie die angegebene Adresse und den Firmenwortlaut, damit wir Ihnen eine korrekte Rechnung
        ausstellen können. Wir bitten um Verständnis, dass wir bei nachträglichen Korrekturen eine Bearbeitungsgebühr
        von € 30, - verrechnen müssen.
        <br>
        13. Hiermit bestätige ich den unter Punkt „Optischer Zustand" erfassten Zustand meines Gerätes. Es wurden in
        meinem Beisein Fotos erstellt, welche den tatsächlichen Zustand belegen. Ferner bestätige ich die oben
        angeführten Bedingungen gelesen, verstanden und inhaltlich voll akzeptiert zu haben.
        <br>
        14. Mit meiner Unterschrift bei der Abholung bestätige ich, dass das Gerät kontrolliert wurde und keine
        erkennbaren Schäden aufweist. Sollten Schäden vorhanden sein, sind diese am Serviceauftrag zu vermerken.

    </p>
</mat-dialog-content>
<mat-dialog-actions class="right">
    <button (click)="confirm()" class="btn btn-primary btn-right">CLOSE</button>
</mat-dialog-actions>
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { 
  BranchService, 
  DeviceBrandModel, 
  DeviceModel, 
  DeviceModelModel, 
  DeviceService, 
  DeviceVariantModel, 
  InsuranceSupplierModel, 
  MobileProviderModel,
  BranchModel
} from 'src/app/shared';
import { DeviceSupplierModel } from 'src/app/shared/models/device-supplier.model';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-device-details',
  templateUrl: './edit-device-details.component.html',
  styleUrls: ['./edit-device-details.component.scss']
})
export class EditDeviceDetailsComponent implements OnInit {
  deviceForm: FormGroup;
  deviceVariants: DeviceVariantModel [] = [];
  deviceSuppliers: DeviceSupplierModel[] = [];
  deviceInsurance: InsuranceSupplierModel[] = [];
  mobileProviders: MobileProviderModel[] = [];
  device: DeviceModel = new DeviceModel();

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<EditDeviceDetailsComponent>,
    private deviceService: DeviceService,
  ) { 
    this.device = data
  }

  ngOnInit(): void {
    
    this.buildDeviceForm();
   
    if (this.device) {
      this.deviceService.getDevice(this.device?.company_id, this.device?.branch_id, this.device?.id)
        .subscribe(device => { 
          this.device = device;
          this.buildDeviceForm().patchValue({
            device_supplier_id: device?.device_supplier?.id,
            start_price_id: device?.device_price?.id,
            reference_number: device?.device_price?.start_price,
            extend_date: device?.device_lifecycle?.date_end,
            ...device
        })
      })
      this.getDeviceDetails();
    }
  }


  public buildDeviceForm(): FormGroup {
   return this.deviceForm = this.fb.group({
      id: [null],
      serial_number: [null],
      imei: [null],
      device_status_id: [1],
      //device_variant_id: [null],
      mobile_provider_id: [null],
      device_supplier_id: [null],
      insurance_id: [null],
      sim: [null],
      phone_number: [],     
      employee: [null],
      employee_id: [null],
      reference_number: [],
      branch_we_cust_id: [],
      ticket_id: [],
      order_number: [],
      extend_date: []
    });
  }

  getDeviceDetails() {
    //this.getDeviceVariants();
    this.getDeviceSupplier();
    this.getDeviceInsurance();  
    this.getMobileProviders();
  }


  getDeviceVariants() {
    const deviceModelID = this.device?.device_variant?.device_model_id;
    const deviceBrandID = this.device?.device_variant?.device_model?.brand_id;
    this.deviceService.getDeviceVariants(deviceBrandID, deviceModelID)
    .subscribe(variants => this.deviceVariants = variants.rows);
  }



  getDeviceSupplier() {
    this.deviceService.getDeviceSuppliers()
    .subscribe(supplier => this.deviceSuppliers = supplier.rows);
  }

  getDeviceInsurance() {
    this.deviceService.getInsurances()
    .subscribe(insurance => this.deviceInsurance = insurance.rows)
  }
  getMobileProviders(): void {
    this.deviceService.getMobileProviders().subscribe(response => {
      this.mobileProviders = response.rows;
    });
  }
  onDateSelect(date) {
    this.deviceForm.patchValue({extend_date: moment(date).format('YYYY-MM-DD')})
  }
  confirm() {
    this.dialogRef.close(this.deviceForm.value);
  }
}

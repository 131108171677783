import { Pipe, PipeTransform } from "@angular/core";
import { UserModel } from "../models";

@Pipe({ name: "initials" })
export class AvatarInitialsPipe implements PipeTransform {
  transform(user: UserModel): string {
    if (user.first_name) {
      const initials = `${user?.first_name.substring(0,1)}${user?.last_name.substring(0, 1)}`;
      return initials;
    }
  }
}

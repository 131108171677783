import { Injectable } from '@angular/core';
import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()

export class NotificationService {

    private serviceUrl = `${environment.apiUrl}/service`;

    constructor(private http: HttpClient) {}

    getAnfangNotification(): Observable<any> {
        return this.http.get(`${this.serviceUrl}/desk/notification`)
        .pipe(catchError((error: any) => throwError(error)));  
    }
}
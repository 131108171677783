export class BranchModel {
  id: number;
  name?: string;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  zip?: string;
  company_id?: number;
  branch_id?: number;
  haai_id?: number;
}

<form [formGroup]="modifyInsuranceDetails" (ngSubmit)="confirm()">
    <div mat-dialog-content>
      <p>Insurance details</p>
      <div class="form-group row">
        <div class="col-md-12 col-lg-12">
          <mat-form-field class="full-width">
            <mat-select placeholder="Insurance type" formControlName="insurance_id">
                <mat-option *ngFor="let insurance of insurances" [value]="insurance.id">
                    {{ insurance.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        </div>
      </div>
      <div class="form-group row">
          <div class="col-md-12 col-lg-12">
            <div class="form-group">
              <mat-form-field class="full-width">
                <mat-select placeholder="Mobile Provider" formControlName="mobile_provider_id">
                  <mat-option *ngFor="let mobileProvider of mobileProviders" [value]="mobileProvider.id">
                      {{ mobileProvider.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
      </div>
    </div>
    
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button (click)="confirm()">Update</button>
    </div>
    </form>
    
import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { UserModel } from "../models";

@Injectable()
export class AuthenticationService {
  loggedUser;
  private backendUrl = `${environment.authUrl}`;

  constructor(private router: Router, private http: HttpClient) {}

  isAuthenticated() {
    let currentUser = localStorage.getItem("current_user");
    if (currentUser !== null) return true;
    return false;
  }

  public getToken(): string {
    let currentUser = localStorage.getItem("current_user");
    if (currentUser !== null) {
      this.loggedUser = JSON.parse(currentUser);
      return this.loggedUser.token;
    }
    return null;
  }

  public getCurrentUserId(): string {
    let currentUser = localStorage.getItem("current_user");
    if (currentUser !== null) {
      this.loggedUser = JSON.parse(currentUser);
      return this.loggedUser.id;
    }
    return null;
  }

  login(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/login`, data).pipe(catchError((error: any) => throwError(error)));
  }

  signup(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/signup`, data).pipe(catchError((error: any) => throwError(error)));
  }

  logout() {
    localStorage.removeItem("current_user");
    this.router.navigate(["/login"]);
  }

  forgotPassword(email: string): Observable<any> {
    return this.http
      .post(`${this.backendUrl}/forgot-password`, { email: email })
      .pipe(catchError((error: any) => throwError(error)));
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.http
      .put(`${this.backendUrl}/forgot-password`, { reset_password_token: token, password: password })
      .pipe(catchError((error: any) => throwError(error)));
  }

  getCurrentUser(): Observable<any> {
    return this.http.get(`${this.backendUrl}/user`).pipe(catchError((error: any) => throwError(error)));
  }
}

<form [formGroup]="salesPersonForm" (ngSubmit)="confirm()">
    <div mat-dialog-content>
      <p>Update sales person name</p>
      <div class="form-group row">
        <div class="col-md-12 col-lg-12">
            <mat-form-field class="full-width" appearance="outline">
              <input matInput  placeholder="Sales person" formControlName="sales_person" required />
            <mat-error> </mat-error>
          </mat-form-field>
        </div>
      </div>
      
      
    </div>
    
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button (click)="confirm()">Update</button>
    </div>
    </form>
    
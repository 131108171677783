<mat-dialog-content class="flex-column">
    <div>
        <i class="pe-7s-trash center"></i>
    </div>
    <div class="flex-column">
        <h2 class="center">Are you sure?</h2>
        <p class="center" *ngIf="customText == null; else elseTemplate">Status will be returned to inactive.</p>
        <ng-template #elseTemplate>
            <p class="center">{{customText}}</p>
        </ng-template>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="center">
    <button mat-button mat-dialog-close class="blue-button">No</button>
    <button mat-button (click)="confirm()" class="red-button">Yes</button>
</mat-dialog-actions>
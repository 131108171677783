import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'app-edit-sc-data',
  templateUrl: './edit-sc-data.component.html',
  styleUrls: ['./edit-sc-data.component.scss']
})
export class EditScDataComponent implements OnInit {
  scDataForm: FormGroup;
  scData;
  accessoriesList: { name: string, checked: boolean, description?: string }[] = [
    { name: 'Brick', checked: false },
    { name: 'Charging Cable', checked: false },
    { name: 'Packaging', checked: false },
    { name: 'Other', checked: false },
  ];
  allowDescription: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditScDataComponent>,
    private fb: FormBuilder
  ) { this.scData = data}

  ngOnInit(): void {
    this.initializeForm();
    if (this.data.accessories) {
      this.loadAccessories();
    }
    this.onDescriptionInput();
  }

  initializeForm(): void {
    this.scDataForm = this.fb.group({
      condition_description: this.fb.group({
        condition: [this.data?.condition_description?.condition],
        description: [this.data?.condition_description?.description],
      }),
      accessories: this.fb.group({
        list: this.fb.array([]),
        accessory_description: ['']
      })
    });

    this.accessoriesList.forEach(() => {
      this.accessories.push(this.fb.control(false));
    });
  }

  get accessories(): FormArray {
    return this.scDataForm.get('accessories').get('list') as FormArray;
  }

  confirm(): void {
    
    const selectedAccessories = this.accessoriesList
      .filter((_, index) => this.accessories.at(index).value)
      .map(acc => ({ name: acc.name, ...(acc?.description && { description: acc?.description })}));
    
    const formData = {
      condition_description: this.cleanObject(this.scDataForm.value.condition_description),  
      accessories: selectedAccessories
    }
    
    this.dialogRef.close(formData);
  }

  cleanObject(obj: any): any {
    return Object.keys(obj)
      .filter(key => obj[key] !== null && obj[key] !== '')
      .reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
      }, {});
  }

  accessorySelected(event: MatCheckboxChange, index: number): void {
    this.accessories.at(index).setValue(event.checked);

    // Check if "Other" is selected to allow accessory description
    this.allowDescription = this.accessoriesList.some((acc, idx) => acc.name === 'Other' && this.accessories.at(idx).value);
  }

  loadAccessories(): void {
    this.accessoriesList.forEach((accessory, index) => {
      const matchingItem = this.data.accessories.find(item => item.name === accessory.name);
      if (matchingItem) {
        this.accessories.at(index).setValue(true);
      }
    });
  }

  appendDescription(accessory: string) {
    const accessoryIndex = this.accessoriesList.findIndex(acc => acc?.name === accessory);
    if (accessoryIndex !== -1) {
      this.accessoriesList[accessoryIndex].description = this.scDataForm.get('accessories').get('accessory_description').value;
      console.log(this.accessoriesList);
    }
  }
  
  onDescriptionInput() {
   this.scDataForm.get('accessories').get('accessory_description').valueChanges
      .pipe(
        debounceTime(500),
        tap(val => {
          this.appendDescription('Other');
        })
      )
      .subscribe();
  }
}
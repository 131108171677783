import { CompanyModel } from './company.model';
import { DeviceModel } from './device.model';
import { EmployeeModel } from './employee.model';
import { UserModel } from './user.model';

export class ServiceCasesModel {
    cancelled: boolean;
    company_id: number;
    company: CompanyModel;
    user: UserModel;
    created_at: string;
    updated_at: string;
    device: DeviceModel;
    device_id: number;
    ended_at: string;
    service_document: ServiceDocumentModel;
    id: number;
    service_step_completeds: ServiceStepCompletedModel;
    started_at: string;
    ticket_id: string;
    user_id: number;
    percentage: number;
    currentDay: number;
    additional_info: { 
        coverage_matrix: CoverageMatrix,
        faults: {
            items: FaultItems[];
            totalPrice: number;
        }
    }
    booked: boolean;
    sc_type: string;
} 
export class ServiceStepCompletedModel {
    id: number;
    service_case_id: number;
    service_step: ServiceStepModel;
}

export class ServiceStepModel {
    id: number;
    blocker: boolean;
    name: string;
    nav_code: string;
    show: boolean;
    step_number: number;
    updated_at: string;
}

export class ServiceDocumentModel {
    contact_email: string;
    created_at: string;
    description: string;
    employee: EmployeeModel;
    id: number;
    note: string;
    service_case_id: number;
    accessories: string;
    condition: string;
}

export class CoverageMatrix {
    status_prediction: string;
    insurance: string;
    insurance_end_date: string;
    insurance_start_date: string;
    warranty_end_date: string;
    warranty_start_date: string;
    model: string;
    batteryHealth: string;
    warranty: string;
    legal_accepted_warranty: string;
    serial_number: string;
    accessories: Array<{name: string, description: string}>;
}

export class FaultItems {
    additional_info: string;
    title: string;
    price: string;
}
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services';
import { accessRoles, validRoles } from '../constants/roles';

@Injectable()
export class RolesGuard implements CanActivate {

  constructor(private router: Router, private auth: AuthenticationService) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const user = JSON.parse(localStorage.getItem("current_user"))?.user;

    const canAccess = validRoles.some(role => user?.lissa_roles?.includes(role));

    if (!canAccess) {
      return false;
    }

    const currentRoute = route.routeConfig?.path || 'dashboard';
    const expectedRoles = accessRoles[currentRoute];

    const hasRole = expectedRoles.some(role => user?.lissa_roles?.includes(role));

    if (hasRole) {
      return true;
    }

    const redirectPath = user?.lissa_roles?.includes("service-manager")
      ? '/clients-list'
      : '/dashboard';

    this.router.navigate([redirectPath]);
    return false;
  }

}

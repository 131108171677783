import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()

export class SCDataService {
    private data$ = new BehaviorSubject<any>([]);
    selectedData$ = this.data$.asObservable();

    setData(data: any) {
        this.data$.next(data);
    }

}

<div>
    <form class="wizard-big wizard clearfix mx-3" role="form">
        <mat-dialog-content class="flex-column p-2">
            <div class="col-12 col-md-12">
              <div class="change-status-border">
                <h2 class="left robot-modal-title m-0 pb-3">Robot EMPTY</h2>
                <p class="left robot-modal-description">
                  Information about robot and devices:
                </p>
              </div>
              <div class="row">
                <div class="col-6 d-flex flex-column justify-content-center">
                  <p class="left mb-0 robot-modal-description">Robot: {{robot?.haai_id}}</p>
                  <p class="left mb-0 robot-modal-description">Location: {{robot?.address}}</p>
                  <p class="left mb-0 robot-modal-description">Branches: <span *ngFor="let branch of robot?.storetec_locker_branches">{{branch?.branch?.name}}</span></p>
                  <p class="left mb-0 robot-modal-description">Capacity: {{robot?.capacity}}</p>

                  <p class="left mt-3 robot-modal-description">Devices selected / to empty: {{count}}</p>
                </div>
                <div class="col-6 d-flex justify-content-center">
                  <div class="qrcode-img">
                    <img [src]="qrCode" alt="">
                  </div>
                </div>
              </div>

            </div>
            <div class="col-12 col-md-12">
              <p class="left mb-1 robot-modal-description mt-2">Please write an e-mail address where we can send QR code for robot empty:</p>
              <mat-form-field class="w-100">
                <input matInput placeholder="Email" [formControl]="email">
              </mat-form-field>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions class="center justify-content-between my-2">
            <button mat-button mat-dialog-close class="btn_back">
              <span class="material-icons">
                arrow_back_ios_new
                </span>
                Back</button>
            <button mat-button type="submit" mat-dialog-close class="case-create" (click)="sendMail()">SEND</button>
        </mat-dialog-actions>
    </form>
</div>
import { DeviceModel } from './device.model';

export class DeviceLifecycleModel {
  id: number;
  date_in: string;
  date_in_check: boolean;
  date_start: string;
  date_start_check: boolean;
  date_end: string;
  contract_duration: string;
  months_remaining: string;
  days_remaining: string;
  created_at: string;
  updated_at: string;
  device_id: number;
  device: DeviceModel;
}
export const coverageStatuses = [
    'VERSMCC+',
    'VERSGAR',
    'KOSTGAR',
    'MIGAR',
    'GARANTIE',
    'KOSTEN',
    'MIKOST',
    'GEWÄHRL',
    'REPARATURBONUS',
    'SUPPORT'
]
import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../services';

@Pipe({
  name: 'checkAccess'
})
export class CheckAccessPipe implements PipeTransform {

  constructor(private userService: UserService) {}

  transform(route: string): boolean {
    return this.userService.canAccess(route);
  }

}

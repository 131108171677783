import { DeviceModel } from './device.model';

export class DevicePriceModel {
  id: number;
  start_price: string;
  current_price: string;
  monthly_price: string;
  end_price: boolean;
  created_at: string;
  updated_at: string;
  device_id: number;
  device: DeviceModel;
}
// export const scFaults = [
//     {title: "Displayschutzfolie anbringen", value: '12,34', item_no: 'DL-SUPPORT'},
//     {title: "Komplette Reinigung von iPhone, iPad, iPod", value: '14,90', item_no: 'DL-CLEAN'},
//     {title: "iPhone, iPad Datensicherung oder Datenwiederherstellung", value: '29,90',item_no: 'DL-DATENSICH'},
//     {title: "E-Mail-Account einrichten", value: '29,90', item_no: 'DL-MAIL'},
//     {title: "Apple ID einrichten, Apple ID zurücksetzen", value: '34,90', item_no:'DL-APPLEID'}, 
//     {title: "Datenübertragung auf iPhone, iPad, iPod", value: '39,90', item_no: 'DL-DATATRANSFER-IOS'},
//     {title: "Mac Time Machine Datensicherung", value: '44,90', item_no: 'DL-BACKUP-MACOS'}, 
//     {title: "OS X Installation", value: '69,90', item_no: 'DL-MAC'},
//     {title: "Datenübertragung auf Mac von bestehendem Time Machine Backup", value: '69,90', item_no: 'DL-DATATRANS-MAC-EXT'}, 
// ]

export const scFaults = [
    {title: "Apple ID zurücksetzen", value: '49,99', item_no: 'DL-SUPPORT-SRV'},
    {title: "Arbeitszeit Helpdesk McSHARK", value: '149,99', item_no: 'DL-SUPPORT-SRV'},
    {title: "Datensicherung macOS", value: '79,99', item_no: 'DL-SUPPORT-SRV'},
    {title: "Komplettreinigung (Anschlüsse+Mikro+Lautsprecher)", value: '19,99', item_no: 'DL-SUPPORT-SRV'},
    {title: "Komplettreinigung MAC (Anschlüsse+Mikro+Lautsprecher)", value: '39,99', item_no: 'DL-SUPPORT-SRV'},
    {title: "Datenübertragung iOS", value: '59,99', item_no: 'DL-SUPPORT-SRV'},
    {title: "Datenübertragung macOS", value: '129,99', item_no: 'DL-SUPPORT-SRV'},
    {title: "Datenübertragung iOS extern", value: '99,99', item_no: 'DL-SUPPORT-SRV'},
    {title: "Datenübertragung macOS extern", value: '149,99', item_no: 'DL-SUPPORT-SRV'},
    {title: "Mac Datenrettung", value: '169,99', item_no: 'DL-SUPPORT-SRV'},
    {title: "Datensicherung iOS", value: '39,99', item_no: 'DL-SUPPORT-SRV'},
    {title: "Spezifische Diagnose auf Kundenwunsch", value: '9,99', item_no: 'DL-SUPPORT-SRV'},
    {title: "Dienstleistung IOS", value: '24,90', item_no: 'DL-SUPPORT-SRV'},
    {title: "Dienstleistung Mac", value: '69,99', item_no: 'DL-SUPPORT-SRV'},
    {title: "E-Mail / Apple ID einrichten", value: '34,99', item_no: 'DL-SUPPORT-SRV'},
    {title: "McSHARK Ready", value: '19,99', item_no: 'DL-SUPPORT-SRV'},
    {title: "Mobilgeräterückholung", value: '14,40', item_no: 'DL-SUPPORT-SRV'},
    {title: "Support Allgemein", value: '9,99', item_no: 'DL-SUPPORT-SRV'},
]
<div>
  <div class="panel-wrap">
    <div class="panel">
      <div class="nav">
        <h1 class="mb-0 subtitle">Device Information</h1>
      </div>
      <div class="divider"></div>
      <br />
      <form [formGroup]="deviceForm" (ngSubmit)="confirm()">
        <!-- <div class="row">
          <div class="col-12">
              <div class="form-group row">
                <div class="col-12">
                  <mat-form-field class="full-width">
                    <mat-label>Variant</mat-label>
                    <mat-select formControlName="device_variant_id">
                      <mat-option  *ngFor="let variant of deviceVariants" [value]="variant?.id">{{variant?.description}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col-6">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Serial number</mat-label>
              <input type="text" formControlName="serial_number" matInput />
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>IMEI</mat-label>
              <input type="text" formControlName="imei" matInput />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-form-field class="device_value full-width" appearance="outline">
              <mat-label>Reference number</mat-label>
              <input type="text" formControlName="reference_number" matInput>
            </mat-form-field>
          </div>
        </div>

        <h2 class="subtitle">Lifecycle duration</h2>
        <div class="row">
          <div class="col-12">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Ending</mat-label>
              <input  
              [matDatepicker]="picker"
              (click)="picker.open()" 
              (dateChange)="onDateSelect(deviceForm.get('extend_date').value)"
              matInput formControlName="extend_date" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <h2 class="subtitle">Ordering information</h2>
        <div class="row">
          <div class="col-6">
            <div class="form-group row">
              <div class="col-12">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Device Supplier</mat-label>
                  <mat-select formControlName="device_supplier_id">
                    <mat-option [value]='null'>N/A</mat-option>
                    <mat-option *ngFor="let supplier of deviceSuppliers" [value]="supplier.id">{{supplier?.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row">
              <div class="col-12">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Order number</mat-label>
                  <input type="text" matInput formControlName="order_number">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <h3 class="subtitle">SIM Card</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group row">
              <div class="col-12">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>SIM Card Provider</mat-label>
                  <mat-select formControlName="mobile_provider_id">
                    <mat-option [value]='null'>N/A</mat-option>
                    <mat-option *ngFor="let mobileProvider of mobileProviders" [value]="mobileProvider.id">
                      {{ mobileProvider.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row">
              <div class="col-12">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>SIM Card Number</mat-label>
                  <input matInput formControlName="sim">
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="col-6">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Phone Number</mat-label>
              <input matInput formControlName="phone_number">
            </mat-form-field>
          </div>
        </div>
        <h2 class="subtitle">Insurance Information</h2>
        <div class="row">
          <div class="col-12">
            <div class="form-group row">
              <div class="col-12">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Insurance Supplier</mat-label>
                  <mat-select formControlName="insurance_id">
                    <mat-option [value]='null'>N/A</mat-option>
                    <mat-option *ngFor="let insurance of deviceInsurance" [value]="insurance.id">{{insurance?.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <h2 class="subtitle">Ticket information</h2>
        <div class="row">
          <div class="col-12">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Ticket ID</mat-label>
              <input type="text" matInput formControlName="ticket_id">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>A1 Company ID</mat-label>
              <input matInput formControlName="branch_we_cust_id" />
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <button mat-button mat-dialog-close class="btn btn-cancel mr-1">CANCEL</button>
          <button mat-button class="btn btn-primary" type="submit">SAVE CHANGES</button>
        </div>

      </form>
    </div>
  </div>
</div>
import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { BranchModel } from "../models";

@Injectable()
export class BranchService {
  private assetUrl = `${environment.apiUrl}/asset`;
  private adminUrl = `${environment.apiUrl}/admin`;
  public perPage: number = 10;
  constructor(private router: Router, private http: HttpClient) {}

  getBranches(company_id: number, active: string, filter?: any): Observable<any> {
    let query = `?active=${active}`;
    if (filter != null && filter.branch_id) query = `${query}&name=${filter.branch_id}`
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/branch${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }
  getBranchesList(company_id: number, active: string): Observable<any> {
    return this.http
      .get(`${this.adminUrl}/company/${company_id}/branch?active=${active}`)
      .pipe(catchError((error: any) => throwError(error)));
  }
  getBranchesPerPage(company_id: number, page:number, limit: number, filter: any): Observable<any> {
    let query = `?page=${page}&limit=${limit}&active=1`;
    if (filter) query = `${query}&name=${filter}`;
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/branch${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getBranchById(company_id: number, branch_id: number): Observable<any> {
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/branch/${branch_id}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  createBranch(company_id: number, branch: BranchModel): Observable<any> {
    return this.http
      .post(`${this.assetUrl}/company/${company_id}/branch`, branch)
      .pipe(catchError((error: any) => throwError(error)));
  }

  updateBranch(company_id: number, branch: BranchModel): Observable<any> {
    return this.http
      .put(`${this.assetUrl}/company/${company_id}/branch/${branch.id}`, branch)
      .pipe(catchError((error: any) => throwError(error)));
  }

  deleteBranch(company_id: number, branch_id: number): Observable<any> {
    return this.http
      .delete(`${this.assetUrl}/company/${company_id}/branch/${branch_id}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getCountries(name?: string): Observable<any> {
    return this.http.get(`${this.adminUrl}/config/country${name ? '?name=' + name : ''}`)
    .pipe(catchError((error: any) => throwError(error)));
  }
  
}

import { DeviceModelModel } from './device-model.model';

export class DeviceVariantModel {
    id: number;
    name: string;
    description: string;
    color: string;
    memory: string;
    active: boolean;
    created_at: string;
    updated_at: string;
    device_model_id: number;
    device_model: DeviceModelModel;
  }
  
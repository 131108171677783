import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class PrintService {

  isPrinting = false;
  device_id: string;
  service_id: string;
  constructor(private router: Router) { }

  printDocument(documentName: string, documentData: any) {
    this.isPrinting = true;
    if(documentName == 'ServiceCase') {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['print', 'service-case'], {queryParams : {
          service_id: documentData.ticket_id,
          company: documentData.company.name,
          company_address: documentData.company.address_line_1,
          company_invoice_email: documentData.company.invoice_email,
          reporter: `${documentData.user.first_name} ${documentData.user.last_name}`,
          reporter_email: documentData.user.email,
          device: documentData.device.device_variant.description,
          serial_number: documentData.device.serial_number,
          imei: documentData.device.imei,
          insurance: documentData.insurance,
          date_created: documentData.company.created_at,
          description: documentData.service_document.description,
          note: documentData.service_document.note,
          id: documentData.id
        }})
      );
      window.location.replace(url);
    } else {
      this.router.navigate(
        ['print', documentName], {queryParams: 
         {
           ticket_id: documentData.ticket_id, 
           device_user: documentData.user, 
           serial_number: documentData.serial_number, 
           pool_device_serial: documentData.pool_device_serial, 
           device_name: documentData.device_name,
           pool_device_name: documentData.pool_device_name,
           note: documentData.note}}
       );
    }
   
  }

  onDataReady(device_id?) {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([`/service-cases/info/${device_id}`]);
    }, 2000);
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<TermsComponent>) { }

  ngOnInit(): void {
  }

  confirm() {
    this.dialogRef.close(true);
  }

}

import { Injectable } from '@angular/core';
import { throwError, Observable } from "rxjs";
import { catchError, filter } from "rxjs/operators";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
@Injectable()
export class ServiceManagerService {
    private serviceUrl = `${environment.apiUrl}/service`;
    public perPage: number = 10;
    constructor(
        private router: Router, 
        private http: HttpClient
    ) { }
    
    getUnresolvedCount(): Observable<any>{
        return this.http.get(`${this.serviceUrl}/service-case/stats/unresolved-count`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getAverageServiceTime(): Observable<any>{
        return this.http.get(`${this.serviceUrl}/service-case/stats/average-service-time`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getLastSevenDaysStats(): Observable<any>{
        return this.http.get(`${this.serviceUrl}/service-case/stats/last-seven-days`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getServiceCases(limit: number, page: number, filterObject?: any): Observable<any> {
        let params = new HttpParams();
        let query = `?page=${page}&limit=${limit}`

        Object.keys(filterObject).forEach(k => { 
            if (filterObject[k] !== null && filterObject[k] !== '' && typeof filterObject[k] != 'object') {
                params = params.set(k, filterObject[k]);
            }
        });
        return this.http.get(`${this.serviceUrl}/service-case${query}`, {params})
        .pipe(catchError((error: any) => throwError(error)));
    }
    getServiceCasebyId(service_id: number): Observable<any> {
        return this.http.get(`${this.serviceUrl}/service-case/${service_id}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getServucesCasesbyTicketID(ticket_id: string): Observable<any>{
        return this.http.get(`${this.serviceUrl}/service-case/search?ticket_id=${ticket_id}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getServiceManagerCompanies(page: number, limit: number): Observable<any> {
        return this.http.get(`${this.serviceUrl}/company?page=${page}&limit=${limit}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getClientServiceSteps() :Observable<any> {
        return this.http.get(` ${this.serviceUrl}/service-case/service-steps?clientSide=true`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getServiceSteps() :Observable<any> {
        return this.http.get(` ${this.serviceUrl}/service-case/service-steps`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getEscalatedServiceCases(page?:number, limit?: number): Observable<any>{
        let query = page && limit ? `?page=${page}&limit=${limit}` : '';
        return this.http.get(` ${this.serviceUrl}/service-case/stats/past-deadline${query}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
}
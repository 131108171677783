import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor() { }

  imgSrc = [{
    clientsGray: '../assets/icons/new_icons/icon-menu-clients.svg',
    clientsWhite: '../assets/icons/new_icons/icon-menu-clients.svg',
    deviceTool: '../assets/icons/new_icons/device_tool.svg',
    userGray: '../assets/icons/v2-icons/clients-management-icon-colored.svg',
    userWhite: '../assets/icons/v2-icons/clients-management-icon.svg',
    employeeGray: '../assets/icons/Navigation_gray_Employees.svg',
    employeeWhite: '../assets/icons/Navigation_white_Employees.svg',
    branchGray: '../assets/icons/new_icons/icon-menu-service-case.svg',
    branchWhite: '../assets/icons/new_icons/icon-menu-service-case.svg',
    devicesGray : '../assets/icons/Navigation_gray_Devices.svg',
    devicesWhite: '../assets/icons/Navigation_white_Devices.svg',
    brandsGray: '../assets/icons/brands_icon.svg',
    brandsWhite: '../assets/icons/brands_icon_white.svg',
    warrantiesGray: '../assets/icons/warranties_icon.svg',
    warrantiesWhite: '../assets/icons/warranties_icon_white.svg',
    modelGray: '../assets/icons/model_icon.svg',
    modelWhite: '../assets/icons/model_icon_white.svg',
    insurersGray: '../assets/icons/insurers_icon.svg',
    insurersWhite: '../assets/icons/insurers_icon_white.svg',
    providersGray: '../assets/icons/providers_icon.svg',
    providersWhite: '../assets/icons/providers_icon_white.svg',
    countryGray: '../assets/icons/country_icon.svg',
    countryWhite: '../assets/icons/country_icon_white.svg',
    variantsGray: '../assets/icons/variants_icon.svg',
    variantsWhite: '../assets/icons/variants_icon_white.svg',
    dashboardWhite: '../assets/icons/new_icons/icon-menu-dashboard.svg',
    dashboardGray: '../assets/icons/new_icons/icon-menu-dashboard.svg',
    hoverMobileWhite: '../assets/icons/mobile_circle_icon_hover.svg',
    hoverTabletWhite: '../assets/icons/tablet_circle_icon_hover.svg',
    hoverLaptopWhite: '../assets/icons/laptop_circle_icon_hover.svg',
    hoverWatchWhite: '../assets/icons/watch_circle_icon_hover-2.svg',
    eolGray: '../assets/icons/eol_icon.svg',
    eolWhite: '../assets/icons/eol_icon_white.svg',
    helpdeskWhite: '../assets/icons/v2-icons/helpdesk-icon.svg',
    helpdeskColored: '../assets/icons/v2-icons/helpdesk-icon-colored.svg' 
  }];
}

import { BranchModel, DeviceModel } from "./index";

export class EmployeeModel {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  corporate_id: string;
  company_id: number;
  device_price_summary: any;
  branch: BranchModel;
  status: boolean;
  branch_id: number;
  devices: DeviceModel[];
}
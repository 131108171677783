import { Injectable } from '@angular/core';
import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class RobotService {
    private serviceUrl = `${environment.apiUrl}/service`;
    private authUrl = `${environment.authUrl}/user`;
    private apiUrl = `${environment.apiUrl}/asset`;
    private sendMailUrl = `${environment.apiUrl}`;
    constructor(private http: HttpClient) { }

    getLockers(page?: number, limit?: number, filterObject?: any): Observable<any> {
        let query = `?page=${page}&limit=${limit}`;
        let params = new HttpParams();
        Object.keys(filterObject).forEach(k => {
            if (filterObject[k] && typeof filterObject[k] != 'object') {
                params = params.set(k, filterObject[k]);
            }})
        return this.http.get(`${this.serviceUrl}/locker${query}`, {params})
        .pipe(catchError((error: any) => throwError(error)));
    }
    getLockerById(locker_id): Observable<any> {
        return this.http.get(`${this.serviceUrl}/locker/${locker_id}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    updateLocker(locker_id, locker): Observable<any> {
        return this.http.post(`${this.serviceUrl}/locker/${locker_id}/companies-and-branches`, locker)
        .pipe(catchError((error: any) => throwError(error)));
    }
    createLocker(locker): Observable<any> {
        return this.http.post(`${this.authUrl}/locker`, locker)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getAllDevicesFromLocker(
        lockerID, 
        page?, 
        limit?,  
        include_action_needed?: boolean,
        include_pending_robot_actions?: boolean): Observable<any> {
        let query;
        if (page || limit) { query = `?page=${page}&limit=${limit}`; }
        if (include_action_needed)  query += `&include_action_needed=${include_action_needed}`;
        if (include_pending_robot_actions)  query += `&include_pending_robot_actions=${include_pending_robot_actions}`;
        return this.http.get(`${this.serviceUrl}/locker/${lockerID}/search-devices${query ? query : ''}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    createEmptyReq(devices, lockerID): Observable<any>{
        return this.http.post(`${this.serviceUrl}/locker/${lockerID}/empty`, {device_ids: devices})
        .pipe(catchError((error: any) => throwError(error)));
    }
    getActionById(actionID): Observable<any> {
        return this.http.get(`${this.apiUrl}/locker/action/${actionID}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getRobotEmptyRequests(lockerID?: number): Observable<any> {
        return this.http.get(`${this.serviceUrl}/locker/action?${lockerID ? 'locker_id=' + lockerID : ''}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    sendQrMail(action: number, email: string): Observable<any> {
        return this.http.post(`${this.sendMailUrl}/integration/storetec/action/${action}/send-mail?email=${email}`, {})
        .pipe(catchError((error: any) => throwError(error)));
    }
}
import { DeviceModel } from './device.model';
import { EmployeeModel } from './employee.model';
import { UserModel } from './user.model';
import { DeviceStatusModel } from './device-status.model';

export class DeviceActivityModel {
  id: number;
  title: string;
  note: string;
  automatic: boolean;
  created_at: string;
  updated_at: string;
  activity_date: string;
  employee_id: number;
  employee: EmployeeModel;
  user_id: number;
  user: UserModel;
  device_id: number;
  device: DeviceModel;
  device_status_id: number;
  device_status: DeviceStatusModel;
}
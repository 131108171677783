<form [formGroup]="modifyDeviceSpec" (ngSubmit)="confirm()">
    <div mat-dialog-content>
      <p>Device Spec</p>
      <div class="form-group row">
        <div class="col-md-12 col-lg-12">
            <mat-form-field class="full-width">
              <input matInput  placeholder="IMEI" formControlName="imei" required />
            <mat-error> </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12 col-lg-12">
            <div class="form-group">
                <mat-form-field class="full-width">
                  <input matInput  placeholder="Serial number" formControlName="serial_number" required />
                </mat-form-field>
                <mat-error> </mat-error>
            </div>
          </div>
          <div class="col-md-12 col-lg-12">
            <div class="form-group">
              <mat-form-field class="full-width">
                <mat-select placeholder="Supplier name" formControlName="device_supplier_id" required>
                  <mat-option *ngFor="let deviceSupp of deviceSuppliers" [value]="deviceSupp.id">
                    {{deviceSupp.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
      </div>
      <!-- <div class="form-group row">
        <div class="col-12 col-md-6">
          <mat-form-field class="full-width">
            <mat-select matInput  placeholder="Capacity" formControlName="memory" required >
              <mat-option value="32 GB">32</mat-option>
              <mat-option value="64 GB">64</mat-option>
              <mat-option value="128 GB">128</mat-option>
              <mat-option value="256 GB">256</mat-option>
              <mat-option value="512 GB">512</mat-option>
              <mat-option value="1 TB">1</mat-option>
              <mat-option value="2 TB">2</mat-option>  
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6">
          <mat-form-field class="full-width">
            <mat-select matInput  placeholder="Color" formControlName="color">
              <mat-option value="Red">Red</mat-option>
                      <mat-option value="Black">Black</mat-option>
                      <mat-option value="Blue">Blue</mat-option>
                      <mat-option value="White/Silver">White/Silver</mat-option>
                      <mat-option value="Space Gray/Black">Space Gray/Black</mat-option>
                      <mat-option value="Silver/White">Silver/White</mat-option>
                      <mat-option value="Aura Glow">Aura Glow</mat-option>
                      <mat-option value="Aura Black">Aura Black</mat-option>
                      <mat-option value="Aura Red">Aura Red</mat-option>
                      <mat-option value="Prism">Prism</mat-option>
                      <mat-option value="Prism White">Prism White</mat-option>
                      <mat-option value="Prism Black">Prism Black</mat-option>
                      <mat-option value="Prism Blue">Prism Blue</mat-option>
                      <mat-option value="Prism Crush Red">Prism Crush Red</mat-option>
                      <mat-option value="Prism Crush White">Prism Crush White</mat-option>
                      <mat-option value="Prism Crush Black">Prism Crush Black</mat-option>
                      <mat-option value="Crush Black">Crush Black</mat-option>
                      <mat-option value="Silver/White">Silver/White</mat-option>
                      <mat-option value="White">White</mat-option>
                      <mat-option value="Gold">Gold</mat-option>
                      <mat-option value="Sky blue">Sky blue</mat-option>
                      <mat-option value="White">White</mat-option>
                      <mat-option value="Pink">Pink</mat-option>
                      <mat-option value="Yellow">Yellow</mat-option>
                      <mat-option value="Purple">Purple</mat-option>
                      <mat-option value="Green">Green</mat-option>
                      <mat-option value="Space Gray">Space Gray</mat-option>
                      <mat-option value="Silver">Silver</mat-option>
                      <mat-option value="Midnight Green">Midnight Green</mat-option>
                      <mat-option value="Space Black">Space Black</mat-option>
                      <mat-option value="Coral">Coral</mat-option>
                      <mat-option value="Rose gold">Rose gold</mat-option>
                      <mat-option value="Jet Black">Jet Black</mat-option> 
            </mat-select>
            <mat-error> </mat-error>
          </mat-form-field>
        </div>
      </div> -->
    </div>
    
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button (click)="confirm()">Update</button>
    </div>
    </form>
    
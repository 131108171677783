<form [formGroup]="modifyAccountForm" (ngSubmit)="confirm()">
<div mat-dialog-content>
  <p>Company details</p>
  <div class="form-group row">
    <div class="col-md-12 col-lg-12">
      <mat-form-field class="full-width">
        <input matInput  formControlName="name" placeholder="Company name" required />
        <mat-error> </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-md-12 col-lg-12">
        <div class="form-group">
          <mat-form-field class="full-width">
            <input matInput  formControlName="address_line_1" placeholder="Address Line 1" required />
            <mat-error> </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-12 col-lg-12">
        <div class="form-group">
          <mat-form-field class="full-width">
            <input matInput  placeholder="Address Line 2" formControlName="address_line_2"/>
            <mat-error> </mat-error>
          </mat-form-field>
        </div>
      </div>
  </div>
  <div class="form-group row">
    <div class="col-12 col-md-6">
      <mat-form-field class="full-width">
        <input matInput  placeholder="City" formControlName="city" required />
      </mat-form-field>
    </div>
    <div class="col-12 col-md-6">
      <mat-form-field class="full-width">
        <input matInput  placeholder="ZIP" formControlName="zip" required />
      </mat-form-field>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="confirm()">Update</button>
</div>
</form>

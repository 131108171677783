import { BranchModel } from "./index";
import { CompanyModel } from './company.model';

export class UserModel {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  status: boolean;
  phone: number;
  role: string;
  corporate_id: string;
  company_id: number;
  branch: BranchModel;
  branch_id: number;
  company: CompanyModel;
  user_roles: UserRoles[];
  lissa_roles: string[];
  lissa_modules: string[];
}

export class UserRoles {
  id: number;
  role: string;
  userID: number;
  user_id: number
}
<div class="center upload-modal-dialog" mat-dialog-content>
    <div class="upload-header mb-2 mr-4 ml-4">
        <h3 >Bulk uploader</h3>
    </div>
    <div class="main-content" [formGroup]="uploadForm">
        <div class="radio-wrapper" >
            <p>Type of action</p>
            <mat-radio-group  class="d-flex flex-column" formControlName="upload_type_info" #value>
                <mat-radio-button *ngFor="let uploadTypeInfo of uploadTypeInfos" [value]="uploadTypeInfo">{{uploadTypeInfo?.radioText}}</mat-radio-button>
              </mat-radio-group>
        </div>
        <div *ngIf="this.uploadForm?.value?.upload_type_info?.type !== 'rent'" class="select-wrapper">
            <p>Please select a branch: </p>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Branch</mat-label>
                <mat-select formControlName="branch_id">
                    <mat-option *ngFor="let branch of branchesList" [value]="branch?.id">
                        {{branch?.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="this.uploadForm?.value?.upload_type_info?.type === 'rent'" class="select-wrapper">
            <p>Selected company: </p>
            <mat-form-field class="w-100" appearance="outline" disabled>
                <mat-label>{{company_name}}</mat-label>
                <mat-select disabled>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="upload-wrapper">
            <div>
                <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="droppedFile($event)"  class="upload-dropzone" >
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        <div class="row upload-box-content">
                            <div class="ml-1 mb-1 d-flex flex-row align-items-center">
                                <div>
                                    <span class="mr-1 material-icons upload-icon">
                                        {{this.uploadForm?.value?.upload_type_info?.icon}}
                                    </span>
                                </div>
                                <div class="text-dark pb-2">
                                    {{this.uploadForm?.value?.upload_type_info?.header}}
                                </div>
                            </div>
                            <div>
                                <div class="upload-border">
                                    <span class="upload-drop-text">Drag & drop files</span> <br>
                                    <span>or <span (click)="openFileSelector()"> <a class="browse-link"> browse Files</a> </span> </span>
                                </div>
                            </div>
                        </div>  
                    </ng-template>
                </ngx-file-drop>
            </div>
        </div>
    </div>
</div>
<div class="instruction-links">
    <div class="d-flex flex-column">
        <p class="download-info-text">Instructions on how to use the bulk upload function can be found bellow:</p>
        <a class="download-link" (click)="downloadFile(this.uploadForm?.value?.upload_type_info?.file_example_url)">{{this.uploadForm?.value?.upload_type_info?.file_example_url}}</a>
        <a class="download-link" (click)="downloadFile(filesUrl.manual_de)">Manual DE.pdf</a>
        <a class="download-link" (click)="downloadFile(filesUrl.manual_en)">Manual EN.pdf</a>
    </div>
</div>
<div mat-dialog-actions class="upload-footer">
    <button mat-button mat-dialog-close class="ml-auto">Cancel</button>
</div>


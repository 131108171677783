import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services';
import { UserModel } from '../../models';
@Component({
  selector: 'app-modify-details',
  templateUrl: './modify-details.component.html',
  styleUrls: ['./modify-details.component.scss']
})
export class ModifyDetailsComponent implements OnInit {
  currentUser = new UserModel();
  modifyAccountForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ModifyDetailsComponent>,
    private toastr: ToastrService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.modifyAccountForm = this.fb.group({
      name: [null, Validators.required],
      address_line_1: [null, Validators.required],
      address_line_2: [null],
      city: [null, Validators.required],
      zip: [null, Validators.required]
    })
    this.getCurrentUser();
  }
  getCurrentUser() {
    this.userService.getCurrentUser().subscribe((user: UserModel) => {
      this.currentUser = user;
      this.modifyAccountForm.patchValue(this.currentUser.company);
    })
  }
  
  confirm() {
      if(this.modifyAccountForm.valid) {
        let data = {
          name: this.modifyAccountForm.value.name,
          address_line_1: this.modifyAccountForm.value.address_line_1,
          address_line_2: this.modifyAccountForm.value.address_line_2,
          city: this.modifyAccountForm.value.city,
          zip: this.modifyAccountForm.value.zip
        }
        this.dialogRef.close(data);
      } else {
        this.toastr.error("Something went wrong, please try again!")
      }
  }

}

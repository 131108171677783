import { Component, OnInit } from '@angular/core';
import { UserService, UserModel, ImagesService } from 'src/app/shared';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public currentUser: UserModel = new UserModel();
  public images;
  public isExpanded: { [key: string]: boolean } = {"servicedesk": true};
  public depUrl = environment.depUrl;

  constructor(
    private userService: UserService,
    private imageService: ImagesService,
    private router: Router
  ) { }

  ngOnInit() {
    this.images = this.imageService.imgSrc;
    this.getCurrentUser();
  }

  expandSubMenu(item: string) {
    this.isExpanded[item] = true;
  }
  toggleSubMenu(event: Event, item: string) {
    event.preventDefault();
    event.stopPropagation();
    this.isExpanded[item] = !this.isExpanded[item];
  }

  public getCurrentUser(): void {
    this.userService.getCurrentUser().subscribe((user: UserModel) => {
      this.currentUser = user;
    });
  }

  onNavigate(event: Event, url: string) {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate([url]);
  }

  onRedirectToDEP() {
    let loggedUser = localStorage.getItem('current_user');
    window.open(`${this.depUrl}/?user=${loggedUser}`);
  }
}

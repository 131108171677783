import { BranchModel } from "./branch.model";
import { CompanyModel } from "./company.model";

export class RobotModel {
    id: number;
    haai_id: number;
    uuid: number;
    address: string;
    serial_number: string;
    pin: {
        lat: string,
        lon: string
    };
    capacity: number;
    zip: string;
    city: string;
    country: string;
    storetec_locker_companies: CompanyModel;
    storetec_locker_branches: BranchModel;
    created_at: string;
    updated_at: string;
}
import { CountryModel } from './country.model';

export class MobileProviderModel {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  country_id: number;
  country: CountryModel;
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-update-sales-person',
  templateUrl: './update-sales-person.component.html',
  styleUrls: ['./update-sales-person.component.scss']
})
export class UpdateSalesPersonComponent implements OnInit {
  public salesPersonForm: FormGroup;
  salesPerson: string;
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<UpdateSalesPersonComponent>,
  ) { 
    this.salesPerson = data;
  }

  ngOnInit(): void {
    this.salesPersonForm = this.fb.group({
      sales_person: []
    })

    if (this.salesPerson) {
      this.salesPersonForm.patchValue({sales_person: this.salesPerson});
    }
  }

  confirm(){
    this.dialogRef.close(this.salesPersonForm.value)
  }
}

import { Routes } from "@angular/router";
import { AuthGuard, RolesGuard } from "./shared";

import { ServiceManagerComponent } from "./service-manager/service-manager.component";
import { AuthenticationComponent } from "./authentication/authentication.component";
import { HelpdeskSearchDeviceComponent } from "./service-manager/helpdesk-search-device/helpdesk-search-device.component";
import { accessRoles } from "./shared/constants/roles";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "/dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    component: ServiceManagerComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./service-manager/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard, RolesGuard],
        data: {
          breadscrumb: 'Dashboard',
        }
      },
      {
        path: "service-cases",
        loadChildren: () => import('./service-manager/service-cases/service-cases.module').then(m => m.ServiceCasesModule),
        canActivate: [AuthGuard,RolesGuard],
        data: {
          breadscrumb: 'Service Cases',
        }
      },
      {
        path: "users-list",
        loadChildren: () => import('./service-manager/managers/managers.module').then(m => m.ManagersModule),
        canActivate: [AuthGuard,RolesGuard],
        data: {
          breadscrumb: 'Users List',
        }
      },
      {
        path: "clients-list",
        loadChildren: () => import('./service-manager/service-clients/service-clients.module').then(m => m.ServiceClientsModule),
        canActivate: [AuthGuard,RolesGuard],
        data: {
          breadscrumb: 'Clients List',
        }
      },
      {
        path: "diagnostics",
        loadChildren: () => import('./service-manager/diagnostics/diagnostics.module').then(m => m.DiagnosticsModule),
        canActivate: [AuthGuard,RolesGuard],
        data: {
          breadscrumb: 'Diagnostics',
        }
      },
      {
        path: "robots-list",
        loadChildren: () => import('./service-manager/robot/robot.module').then(m => m.RobotModule),
        canActivate: [AuthGuard,RolesGuard],
        data: {
          breadscrumb: 'Robots List',
        }
      },
      {
        path: "robots-requests",
        loadChildren: () => import('./service-manager/robot-requests/robot-requests.module').then(m => m.RobotRequestsModel),
        canActivate: [AuthGuard,RolesGuard],
        data: {
          breadscrumb: 'Robots Requests',
        }
      },
      {
        path: "device-tool",
        loadChildren: () => import('./service-manager/device-tools/device-tools.module').then(m => m.DeviceToolsModule),
        canActivate: [AuthGuard,RolesGuard],
        data: {
          breadscrumb: 'Device tool',
        }
      },
      {
        path: "search-device",
        loadChildren: () => import('./service-manager/helpdesk-search-device/helpdesk-search.module').then(m => m.HelpdeskSearchModule),
        canActivate: [AuthGuard,RolesGuard],
        data: {
          breadscrumb: 'Device search',
        }
      },
      {
        path: "device-diagnostic",
        loadChildren: () => import('./service-manager/device-diagnostic/device-diagnostic.module').then(m => m.DeviceDiagnosticModule),
        canActivate: [AuthGuard,RolesGuard],
        data: {
          breadscrumb: 'Device Diagnostic',
        }
      },
      {
        path: "service-item-overview",
        loadChildren: () => import('./service-manager/service-item-overview/service-item-overview.module').then(m => m.ServiceItemOverviewModule),
        canActivate: [AuthGuard,RolesGuard],
        data: {
          breadscrumb: 'Service Item Overview',
        }
      },
      {
        path: "stores-list",
        loadChildren: () => import('./service-manager/service-stores/service-stores.module').then(m => m.ServiceStoresModule),
        canActivate: [AuthGuard,RolesGuard],
        data: {
          breadscrumb: 'Service Stores List',
        }
      }
    ],
  },
  {
      path: 'print',
      loadChildren: () => import('./shared/print-layout/print-layout.module').then(m => m.PrintLayoutModule),
  },
  {
    path: "",
    component: AuthenticationComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
      },
    ],
  },
  {
    path: "lissa-lib",
    children: [
      { 
        path: "",
        loadChildren: () => import('./shared/lissa-library/lissa-library.module').then (m => m.LissaLibraryModule)}
    ]
  }
];

import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { UserModel } from "../models";
import { accessRoles } from "../constants/roles";

@Injectable()
export class UserService {
  private authUrl = `${environment.authUrl}`;
  private adminUrl = `${environment.apiUrl}/admin`;
  private assetUrl = `${environment.apiUrl}/auth`;
  private serviceManagerUrl = `${environment.apiUrl}/service`

  constructor(private router: Router, private http: HttpClient) { }

  getUsers(page: number, limit: number, filterObject): Observable<any> {
    let query = `?page=${page}&limit=${limit}`;
    if (filterObject.status != null) query = `${query}&status=${filterObject.status}`;
    if (filterObject.name != null) query = `${query}&name=${filterObject.name}`;

    return this.http.get(`${this.assetUrl}/user/list${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getCurrentUser(): Observable<any> {
    return this.http.get(`${this.authUrl}/user`).pipe(catchError((error: any) => throwError(error)));
  }

  getUserById(userId: number): Observable<any> {
    return this.http.get(`${this.authUrl}/user/${userId}`).pipe(catchError((error: any) => throwError(error)));
  }

  updateUser(userId: number, user: UserModel): Observable<any> {
    return this.http.patch(`${this.authUrl}/user/${userId}`, user).pipe(catchError((error: any) => throwError(error)));
  }
  updateCompany(companyId: number, user: UserModel): Observable<any> {
    return this.http.put(`${this.adminUrl}/company/${companyId}`, user).pipe(catchError((error: any) => throwError(error)));
  }
  getCompanies(pageNumber?: number, limit?: number, filterObject?: any, name?: any): Observable<any> {
    let query = "?";
    if(pageNumber && limit){
      query = `${query}page=${pageNumber}&limit=${limit}`
    }
    if (filterObject?.company != null) {
      query = `${query}&name=${filterObject.company}`
    }
    if (name != null) {
      query = `${query}&name=${name}`
    }
    return this.http.get(`${this.serviceManagerUrl}/company${query}`).pipe(
      catchError((error: any) => throwError(error)));
  }
  createUser(user): Observable<any> {
    return this.http.post(`${this.authUrl}/user`, user)
      .pipe(catchError((error: any) => throwError(error)));
  }
  updateUserByEmail(user): Observable<any> {
    return this.http.patch(`${this.authUrl}/user/${user.email}`, user)
      .pipe(catchError((error: any) => throwError(error)));
  }
  getUserByID(stID: number): Observable<any> {
    return this.http.get(`${this.serviceManagerUrl}/users/${stID}`)
    .pipe(catchError((error: any) => throwError(error)));
  }
  deleteUser(email: string): Observable<any> {
    return this.http.delete(`${this.authUrl}/user/${email}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getServiceManagers(page: number, limit: number, filterOptions?: any): Observable<any> {
    let params = new HttpParams();
    for (const key in filterOptions) {
      if (filterOptions.hasOwnProperty(key) && filterOptions[key] !== '' && filterOptions[key] !== null) {
        params = params.set(key, filterOptions[key]);
      }
    }
    return this.http.get(`${this.authUrl}/user/search?page=${page}&limit=${limit}&service_users=true`, {params})
      .pipe(catchError((error: any) => throwError(error)));
  }
  getFullName(user) {
    if (user) {
      const fullname = `${user.first_name} ${user.last_name}`;
      return fullname;
    } else return "";
  }

  get getLoggedInUser(): UserModel {
    return JSON.parse(localStorage.getItem('current_user')).user as UserModel;
  }

  canAccess(route: string): boolean {
    const user = JSON.parse(localStorage.getItem('current_user'))?.user as UserModel;
    const userRoles = user.lissa_roles
    const allowedRoles = accessRoles[route] || [];
    return allowedRoles.some(role => userRoles.includes(role));
  }

  getMonthlyReport(company_id: number, format: string): Observable<any> {
    return this.http.get(`${this.serviceManagerUrl}/company/monthly-export?company_id=${company_id}&format=${format}`)
    .pipe(catchError((error: any) => throwError(error)));
  }
}

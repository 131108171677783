<mat-dialog-content class="flex-column">
    <div>
        <h2 class="remove-pu__header">{{header}}</h2>
    </div>
    <div class="divider"></div>
    <div class="flex-column">
        <h2 class="left remove-pu__title">{{title}}</h2>
        <p class="left remove-pu__text" *ngIf="customText == null; else elseTemplate">You will not be able to recover deleted entry.</p>
        <ng-template #elseTemplate>
            {{customText}}
        </ng-template>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="center justify-content-end">
    <button mat-button mat-dialog-close class="cancel-btn">CANCEL</button>
    <button mat-button (click)="confirm()" class="confirm-btn">DELETE</button>
</mat-dialog-actions>
import { Pipe, PipeTransform } from "@angular/core";
@Pipe({ name: "activeStep" })
export class ActiveSCStep implements PipeTransform {
  transform(completedSCsteps: any[]) {
    if (completedSCsteps && completedSCsteps.length > 0) {
      const lastCompletedSC = [...completedSCsteps].sort().reverse();
      return lastCompletedSC[0];
    }
    return null;
  }
}

import { Injectable } from '@angular/core';
import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { DeviceDiagnostic } from '../models/interface';


@Injectable()
export class DeviceDiagnosticService {
    private serviceUrl = `${environment.apiUrl}/service`;

    constructor(private http: HttpClient) { }

    set diagnosticItem(diagnosticItem) {
        sessionStorage.setItem('__diagnosticItem', JSON.stringify(diagnosticItem));
    }
  
    get diagnosticItem() {
        return JSON.parse(sessionStorage.getItem('__diagnosticItem'));
    }

    getDiagnostic(serial_number: string): Observable<any> {
        return this.http.get(`https://api.lissa-ems.com/api/ast/service/desk/gsx/get-diagnostics?serial_number=${serial_number}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    
    initDiagnostic(serial_number: string, test_id: string): Observable<DeviceDiagnostic> {
        return this.http.post<DeviceDiagnostic>(`https://api.lissa-ems.com/api/ast/service/desk/gsx/initiate-diagnostic?serial_number=${serial_number}&test_id=${test_id}`, {})
        .pipe(catchError((error: any) => throwError(error)));
    }

    checkDiagnostic(serial_number: string): Observable<any> {
        return this.http.get(`https://api.lissa-ems.com/api/ast/service/desk/gsx/repair-details?serial_number=${serial_number}`)
        .pipe(catchError((error: any) => throwError(error)));
    }

    checkFMIStatus(serial_number: string): Observable<any> {
        return this.http.get(`https://api.lissa-ems.com/api/ast/service/desk/gsx/repair/eligibility?serial_number=${serial_number}`)
        .pipe(catchError((error: any) => throwError(error)));
    } 

    getDiagnosticResults(serial_number: string, limit: number): Observable<any> {
        return this.http.get(`https://api.lissa-ems.com/api/ast/service/desk/gsx/diagnostics/results?serial_number=${serial_number}&limit=${limit}`)
        .pipe(catchError((error: any) => throwError(error)));
    }

    checkSunrocksInsurance(serial_number: string) : Observable<any> {
        return this.http.get(`https://api.lissa-ems.com/api/ast/service/desk/insurance-info?serial_number=${serial_number}`)
        .pipe(catchError((error: any) => throwError(error)));
    }

}
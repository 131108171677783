import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { EmployeeModel } from "../models";

@Injectable()
export class EmployeeService {
  private assetUrl = `${environment.apiUrl}/asset`;

  constructor(private router: Router, private http: HttpClient) {}

  getEmployees(
    company_id: number,
    branch_id: number,
    page: number,
    limit: number,
    filterObject: any
  ): Observable<any> {
    let query =
      branch_id !== null
        ? `?page=${page}&limit=${limit}&branch_id=${branch_id}`
        : `?page=${page}&limit=${limit}`;

    if (filterObject.status != null)
      query = `${query}&status=${filterObject.status}`;
    if (filterObject.name != null) query = `${query}&name=${filterObject.name}`;
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/employee${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getEmployeeById(
    company_id: number,
    branch_id: number,
    employee_id: number
  ): Observable<any> {
    const query = branch_id !== null ? `?branch_id=${branch_id}` : "";
    return this.http
      .get(
        `${this.assetUrl}/company/${company_id}/employee/${employee_id}${query}`
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  createEmployee(company_id: number, employee: EmployeeModel): Observable<any> {
    return this.http
      .post(`${this.assetUrl}/company/${company_id}/employee`, employee)
      .pipe(catchError((error: any) => throwError(error)));
  }

  updateEmployee(company_id: number, employee: EmployeeModel): Observable<any> {
    return this.http
      .put(
        `${this.assetUrl}/company/${company_id}/employee/${employee.id}`,
        employee
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  updateEmployeeStatus(
    company_id: number,
    employee: EmployeeModel,
    employeeId
  ): Observable<any> {
    return this.http
      .put(
        `${this.assetUrl}/company/${company_id}/employee/${employeeId}`,
        employee
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  deleteEmployee(company_id: number, employeeId: number): Observable<any> {
    return this.http
      .delete(`${this.assetUrl}/company/${company_id}/employee/${employeeId}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  searchEmployees(
    company_id: number,
    branch_id: number,
    name: string,
    status: string
  ): Observable<any> {
    const query =
      branch_id !== null
        ? `?branch_id=${branch_id}&name=${name}&status=${status}`
        : `?name=${name}&status=${status}`;
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/employee/search${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }
}

import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { DeviceModel, DeviceVariantModel, InsuranceModel } from "../models";
import * as moment from "moment";

@Injectable()
export class DeviceService {
  private assetUrl = `${environment.apiUrl}/asset`;
  private adminUrl = `${environment.apiUrl}/admin`;
  private authUrl = `${environment.apiUrl}`;
  private serviceUrl = `${environment.apiUrl}/service`;
  private publicUrl = `${environment.apiUrl}/public`;

  constructor(private router: Router, private http: HttpClient) {}

  getDeviceTypes(): Observable<any> {
    return this.http
      .get(`${this.adminUrl}/device/type`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getBrandTypes(device_type_id: number): Observable<any> {
    return this.http
      .get(`${this.assetUrl}/device/type/${device_type_id}/brand`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getDeviceModels(brand_id: number, device_type_id: number): Observable<any> {
    return this.http
      .get(
        `${this.assetUrl}/device/brand/${brand_id}/model?device_type_id=${device_type_id}`
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  getDeviceSuppliers(): Observable<any> {
    return this.http
      .get(
        `${this.adminUrl}/config/device-supplier`
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  getDeviceVariants(
    brand_id: number,
    device_model_id: number
  ): Observable<any> {
    return this.http
      .get(
        `${this.assetUrl}/device/brand/${brand_id}/model/${device_model_id}/variant`
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  getMobileProviders(): Observable<any> {
    return this.http
      .get(`${this.assetUrl}/config/mobile-provider`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getInsurances(): Observable<any> {
    return this.http
      .get(`${this.assetUrl}/config/insurance`)
      .pipe(catchError((error: any) => throwError(error)));
  }
  getInsuranceProviders(): Observable<any> {
    return this.http
      .get(`${this.authUrl}/admin/config/insurance-supplier`)
      .pipe(catchError((error: any) => throwError(error)));
  }
  getDeviceStatuses(): Observable<any> {
    return this.http
      .get(`${this.assetUrl}/device/status`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getDeviceImage(path: string): Observable<any> {
    return this.http.get(`${this.publicUrl}${path}`, {responseType: 'text'})
    .pipe(catchError((error: any) => throwError(error)));
  }

  createDevice(device: DeviceModel): Observable<any> {
    device.device_lifecycle.date_in = this.formatDate(
      device.device_lifecycle.date_in
    );
    device.device_lifecycle.date_start = this.formatDate(
      device.device_lifecycle.date_start
    );
    return this.http
      .post(`${this.assetUrl}/company/${device.company_id}/device`, device)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getDevices(
    company_id: number,
    branch_id: number,
    page: number,
    limit: number,
    filterObject: any,
    sort?: any,
    isDesc?: boolean,
  ): Observable<any> {
    let query =
      branch_id !== null
        ? `?page=${page}&limit=${limit}&branch_id=${branch_id}`
        : `?page=${page}&limit=${limit}`;
    if (filterObject.device_type_id != null)
      query = `${query}&device_type_id=${filterObject.device_type_id}`;
    if (filterObject.device_status_id != null)
      query = `${query}&device_status_id=${filterObject.device_status_id}`;
    if (filterObject.employee_id != null)
      query = `${query}&employee_id=${filterObject.employee_id}`;
    if (filterObject.serial_number != null)
      query = `${query}&serial_number=${filterObject.serial_number}`;
    if (filterObject.description != null)
      query = `${query}&description=${filterObject.description}`;
    if (filterObject.lifecycle_status != null) {
      query = `?page=${page}&limit=${limit}&lifecycle_status=${filterObject.lifecycle_status}`;
    }
    if( sort !== null && sort == 'sort_price') {
      query = `${query}&sort_price=${isDesc}`;
    }
    if( sort !== null && sort == 'sort_added') {
      query = `${query}&sort_added=${isDesc}`;
    }
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/device${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getDevice(
    company_id: number,
    branch_id: number,
    device_id: number
  ): Observable<any> {
    const query = branch_id !== null ? `?branch_id=${branch_id}` : "";
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/device/${device_id}${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }
  updateDevice(
    company_id: number,
    branch_id: number,
    device_id: number,
    device: DeviceModel
  ): Observable<any> {
    const query = branch_id !== null ? `?branch_id=${branch_id}` : "";
    return this.http
      .put(
        `${this.assetUrl}/company/${company_id}/device/${device_id}${query}`,
        device
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  searchDevices(
    company_id: number,
    branch_id: number,
    serial_number: string,
    status: string
  ): Observable<any> {
    const query =
      branch_id !== null
        ? `?page=1&limit=10&branch_id=${branch_id}&serial_number=${serial_number}&status=${status}`
        : `?page=1&limit=10&serial_number=${serial_number}&status=${status}`;
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/device?${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }
  searchDevicesByImeiOrSerial(
    company_id: number,
    branch_id: number,
    value: string,
    device_status_id: any,
  ):Observable<any>{
    const query = 
      branch_id !== null
        ? `&serial_imei=${value}&branch_id=${branch_id}&device_status=${device_status_id}`
        : `&serial_imei=${value}`
    return this.http.get(`${this.assetUrl}/company/${company_id}/device/search-device-serial-imei?${query}`)
  }

  assignDevice(
    company_id: number,
    device_id: number,
    data: any
  ): Observable<any> {
    data.device_lifecycle.date_start = this.formatDate(data.device_lifecycle.date_start);
    return this.http
      .post(
        `${this.assetUrl}/company/${company_id}/device/${device_id}/assign`,
        data
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  backToPool(company_id: number, device_id: number): Observable<any> {
    return this.http
      .post(
        `${this.assetUrl}/company/${company_id}/device/${device_id}/pool`,
        {}
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  doaDevice(company_id: number, device_id: number, data): Observable<any> {
    return this.http
      .post(
        `${this.assetUrl}/company/${company_id}/device/${device_id}/doa`,
        data
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  reAssign(company_id: number, device_id: number, employee_id: number): Observable<any> {
    return this.http
    .post(`${this.assetUrl}/company/${company_id}/device/${device_id}/reassign`, {employee_id})
    .pipe(catchError((error: any) => throwError(error)));
  }

  serviceDevice(company_id: number, device_id: number): Observable<any> {
    return this.http
      .post(
        `${this.assetUrl}/company/${company_id}/device/${device_id}/service`,
        {}
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  swapDevice(company_id: number, device_id: number, data: any): Observable<any> {
    return this.http
      .post(
        `${this.assetUrl}/company/${company_id}/device/${device_id}/swap`,
        data
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  loanDevice(company_id: number, device_id: number): Observable<any> {
    return this.http
      .post(
        `${this.assetUrl}/company/${company_id}/device/${device_id}/loan`,
        {}
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  getBrands(): Observable<any> {
    return  this.http.get(`${this.authUrl}/admin/device/brand`).pipe(
        catchError((error: any) => throwError(error)));
  }

  getModels(filterObject?: any): Observable<any> {
    let params = new HttpParams();
    Object.keys(filterObject).forEach(k => {
      if (filterObject[k] && typeof filterObject[k] != 'object') {
          params = params.set(k, filterObject[k]);
      }
    })
    return this.http.get(`${this.authUrl}/admin/device/model`, {params})
    .pipe(catchError ((error: any) => throwError(error)));
  }

  public formatDate(date): string {
    if (date) return moment(date).format("YYYY-MM-DD");
    return date;
  }

  searchVariants(description: string): Observable<DeviceVariantModel[]> {
    return this.http.get<DeviceVariantModel[]>(`${this.adminUrl}/device/variant/general-search?searchStr=${description}`)
    .pipe(catchError ((error: any) => throwError(error)));
  }

  searchDeviceInNAV(serial_imei: string): Observable<any> {
    return this.http.get(`${this.serviceUrl}/desk/search-nav-device?serial_number=${serial_imei}`)
    .pipe(catchError ((error: any) => throwError(error)));
  }

  cancelServiceStep(company_id: number, device_id: number, service_case_id: number): Observable<any>{
    return this.http.post(`${this.assetUrl}/company/${company_id}/device/${device_id}/service-case/${service_case_id}/cancel`, {})
    .pipe(catchError((error: any) => throwError(error)));
  }
}
